import { useNavigation } from '@react-navigation/native'
import { Button, Flex, HStack, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import useGroupEventProperties from '../../../hooks/useGroupEventProperties'
import BaseLayout from '../../../layouts/BaseLayout'
import { useCabanaApi } from '../../../providers/CabanaApiProvider'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import useRoomContext from '../../../providers/RoomProvider'
import { useUserProfileContext } from '../../../providers/UserProfileProvider'
import { useCompleteDailyRec } from '../../home/queries'
import Card from '../../_shared/card/Card'
import FixedContainer from '../../_shared/layout/FixedContainer'
import TextLink from '../../_shared/text/TextLink'
import RoomHeading from '../RoomHeading'
import DeviceSettings from '../settings/DeviceSettings'
import AliasEntry from './AliasEntry.web'
import JoinRoom from './JoinRoom'
import OrientationStepper from './OrientationStepper'
import VideoPreview from './VideoPreview'
import useCustomerIo from '../../../hooks/customerio/useCustomerIo'

const Orientation = () => {
  /* State */
  const { RoomApi } = useCabanaApi()
  const room = useRoomContext()
  const roomClient = useRoomClientContext()
  const [stepperIndex, setStepperIndex] = useState(0)
  const { completeGroup } = useCompleteDailyRec()
  const navigation = useNavigation()
  const { boot, shutdown } = useIntercom()
  const { userProfile } = useUserProfileContext()
  const customerIo = useCustomerIo()

  useEffect(() => {
    if (
      userProfile?.email &&
      userProfile?.id &&
      userProfile?.intercomHmacValue &&
      (userProfile?.goal || userProfile?.customGoal) &&
      userProfile?.professionalRoles
    )
      boot({
        email: userProfile.email,
        userId: userProfile.id,
        userHash: userProfile.intercomHmacValue,
        customAttributes: {
          professionalRoles: JSON.stringify(userProfile.professionalRoles),
          goal: userProfile.goal ?? userProfile.customGoal,
        },
      })
    else boot()

    return shutdown
  }, [userProfile])

  /* Functions */
  const join = () => {
    const spectator = !roomClient.hasPermission || !roomClient.userHasMic

    const role = spectator ? 'SPECTATOR' : 'PARTICIPANT'
    if (room.me.role !== AttendeeRole.Moderator) {
      RoomApi.setParticipantRole(room.roomId, role)
    }
    room.join()
    if (room.activityId && room.me.role !== AttendeeRole.Moderator) {
      customerIo.track(
        'Group Attended',
        useGroupEventProperties(room, {
          Role: role,
          'Has Microphone': roomClient.hasPermission,
          'Total Groups Attended': userProfile.attendedGroupCount + 1,
        })
      )
      completeGroup(room)
    }
  }

  const handleGoToNextStep = [
    () => setStepperIndex((prevIndex) => prevIndex + 1),
    () => setStepperIndex((prevIndex) => prevIndex + 1),
    () => setStepperIndex((prevIndex) => prevIndex),
  ]

  const handleGoToPrevStep = [
    () => navigation.goBack(),
    () => setStepperIndex((prevIndex) => prevIndex - 1),
    () => setStepperIndex((prevIndex) => prevIndex - 1),
  ]

  /* Render */
  return (
    <BaseLayout>
      <FixedContainer size="lg">
        <VStack>
          <Flex mt="2">
            <RoomHeading />
          </Flex>
          <Flex mt="5">
            {/* Moderator view */}
            {room.me.role === AttendeeRole.Moderator ? (
              <>
                <HStack space={3}>
                  <Flex flex={2}>
                    <Card
                      boxProps={{
                        w: '650px',
                      }}
                      title="Preview"
                      hasPadding={{ title: true, actions: true }}
                      body={<VideoPreview />}
                    />
                  </Flex>
                  <Flex flex={1}>
                    <Card
                      boxProps={{
                        h: '100%',
                      }}
                      title="Audio settings"
                      body={
                        <DeviceSettings hasCamera={true} role={room.me.role} />
                      }
                    />
                  </Flex>
                </HStack>
                <HStack alignSelf="flex-end" mt="3">
                  <Button
                    variant="outline"
                    borderColor="muted.300"
                    tintColor="#222B60"
                    textDecorationColor="black"
                    onPress={navigation.goBack}>
                    Cancel
                  </Button>
                  <Button
                    isDisabled={!roomClient.hasPermission}
                    colorScheme="primary"
                    ml="3"
                    onPress={join}>
                    Enter room
                  </Button>
                </HStack>
              </>
            ) : (
              // Participant view
              <OrientationStepper
                titles={[
                  'Enter your nickname',
                  'Audio settings',
                  "You're ready to go!",
                ]}
                steps={[
                  <AliasEntry />,
                  <DeviceSettings role={room.me.role} />,
                  <JoinRoom condition={room.me.alias} handlePress={join} />,
                ]}
                stepConditions={[
                  room.me.alias,
                  room.me.alias,
                  room.me.alias && room.me.alias,
                ]}
                stepperIndex={stepperIndex}
                handleGoToNextStep={handleGoToNextStep}
                handleGoToPrevStep={handleGoToPrevStep}
                setStepperIndex={setStepperIndex}
              />
            )}
          </Flex>
          {room.me.role !== AttendeeRole.Moderator && (
            <Flex mt="4" alignItems="center">
              <Flex>
                <Text bold color="primary.900" textAlign="center">
                  Experiencing a crisis?
                </Text>
                <Text color="primary.900" textAlign="center">
                  <TextLink href="https://www.mycabana.health/resources">
                    View our resources
                  </TextLink>{' '}
                  for immediate support
                </Text>
              </Flex>
            </Flex>
          )}
        </VStack>
      </FixedContainer>
    </BaseLayout>
  )
}

export default Orientation
