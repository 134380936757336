import { Flex, theme, VStack } from 'native-base'
import { useCallback, useEffect, useState } from 'react'
import SEARCH_STATUS from '../../../../domain/enums/ExploreSearch'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import FilterSection from '../../components/explore/FilterSection'
import SearchBarSection from '../../components/explore/SearchBarSection'
import SearchComponent from '../../components/explore/SearchComponent'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import { CategoryFilters, TimeFilters } from '../../constants/filterConstants'
import secureScreen from '../../hooks/authentication/secureScreen'
import useGetOasisColors from '../../hooks/useGetOasisColors'
import DefaultLayout from '../../layouts/DefaultLayout'
import useCustomerIo from '../../hooks/customerio/useCustomerIo'

const SearchScreen = ({ route }) => {
  const routeParsedTimeFilters =
    route.params?.timeFilters != undefined
      ? [TimeFilters[route.params?.timeFilters]]
      : []
  const [timeFilters, setTimeFilters] = useState(routeParsedTimeFilters)

  const routeParsedCategoryFilters =
    route.params?.categoryFilters != undefined
      ? [CategoryFilters(false)[route.params?.categoryFilters].name]
      : []
  const [categoryFilters, setCategoryFilters] = useState(
    routeParsedCategoryFilters
  )

  const [moderatorFilters, setModeratorFilters] = useState([])

  const isDesktop = useDesktopBreakpoint()
  const { tabColor } = useGetOasisColors()
  const customerIo = useCustomerIo()

  const [searchValue, setSearchValue] = useState(
    route.params?.searchValue || ''
  )
  const [searchStatus, setSearchStatus] = useState(
    route.params?.searchValue ? SEARCH_STATUS.finished : SEARCH_STATUS.started
  )

  useEffect(() => {
    customerIo.track('Search Reached')
  }, [])

  const handleFocus = useCallback((isFocused) => {
    if (isFocused) setSearchStatus(SEARCH_STATUS.started)
  }, [])

  const onRemoveAllFilters = useCallback(() => {
    if (
      !searchValue &&
      !timeFilters.length &&
      !categoryFilters.length &&
      !moderatorFilters.length
    )
      setSearchStatus(SEARCH_STATUS.started)
  }, [searchValue, timeFilters, categoryFilters, moderatorFilters])

  useEffect(() => {
    if (
      timeFilters.length > 0 ||
      categoryFilters.length > 0 ||
      moderatorFilters.length > 0
    ) {
      setSearchStatus(SEARCH_STATUS.finished)
    } else if (searchValue == '' && searchStatus == SEARCH_STATUS.finished)
      setSearchStatus(SEARCH_STATUS.started)
  }, [timeFilters, categoryFilters, moderatorFilters])

  const handleSubmit = useCallback(
    (newSearch) => {
      if (newSearch || searchValue) {
        setSearchValue(newSearch ?? searchValue)
        customerIo.track('Searched', {
          query: newSearch ?? searchValue,
          timeFilters: timeFilters.length === 0 ? undefined : timeFilters,
          categoryFilters:
            categoryFilters.length === 0 ? undefined : categoryFilters,
          moderatorFilters:
            moderatorFilters.length === 0 ? undefined : moderatorFilters,
          screen: 'Search',
        })
        setSearchStatus(SEARCH_STATUS.finished)
      }
    },
    [searchValue, timeFilters, categoryFilters, moderatorFilters]
  )

  const onModifyText = useCallback((newSearch) => {
    setSearchValue(newSearch)
    if (newSearch) {
      setSearchStatus(SEARCH_STATUS.typing)
    } else {
      setSearchStatus(SEARCH_STATUS.started)
    }
  }, [])

  const clearFilters = useCallback(() => {
    setCategoryFilters([])
    setTimeFilters([])
    setModeratorFilters([])
  }, [])

  const handleCancel = useCallback(() => {
    setSearchValue('')
    clearFilters()
  }, [])

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)

  return (
    <>
      {!isDesktop ? (
        <DefaultLayout activeLabel="Explore" scrollView={false}>
          <VStack bg={tabColor} h="100%" safeAreaTop>
            <SearchBarSection
              searchValue={searchValue}
              onModifyText={onModifyText}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              handleCancel={handleCancel}
              route={route}
              routeParsedTimeFilters={routeParsedTimeFilters}
              routeParsedCategoryFilters={routeParsedCategoryFilters}
              isFilterModalOpen={isFilterModalOpen}
            />
            <FilterSection
              timeFilters={timeFilters}
              setTimeFilters={setTimeFilters}
              categoryFilters={categoryFilters}
              setCategoryFilters={setCategoryFilters}
              moderatorFilters={moderatorFilters}
              setModeratorFilters={setModeratorFilters}
              onRemoveAllFilters={onRemoveAllFilters}
              setIsFilterModalOpen={setIsFilterModalOpen}
              clearFilters={clearFilters}
            />
            <Flex flex={1} bg="white" borderTopRadius="2xl">
              <SearchComponent
                searchValue={searchValue}
                handleSubmit={handleSubmit}
                setSearchStatus={setSearchStatus}
                searchStatus={searchStatus}
                timeFilters={timeFilters}
                categoryFilters={categoryFilters}
                moderatorsFilters={moderatorFilters}
              />
            </Flex>
          </VStack>
        </DefaultLayout>
      ) : (
        <VStack h="100%" bg="muted.50">
          <Flex bg={tabColor} shadow="3">
            <FixedContainer size="lg">
              <SearchBarSection
                searchValue={searchValue}
                onModifyText={onModifyText}
                handleSubmit={handleSubmit}
                handleFocus={handleFocus}
                handleCancel={handleCancel}
                route={route}
                routeParsedTimeFilters={routeParsedTimeFilters}
                routeParsedCategoryFilters={routeParsedCategoryFilters}
                isFilterModalOpen={isFilterModalOpen}
              />
              <FilterSection
                timeFilters={timeFilters}
                setTimeFilters={setTimeFilters}
                categoryFilters={categoryFilters}
                setCategoryFilters={setCategoryFilters}
                moderatorFilters={moderatorFilters}
                setModeratorFilters={setModeratorFilters}
                onRemoveAllFilters={onRemoveAllFilters}
                setIsFilterModalOpen={setIsFilterModalOpen}
                clearFilters={clearFilters}
              />
            </FixedContainer>
          </Flex>
          <Flex flex="1" w={theme.breakpoints.lg} py="10" mx="auto">
            <Flex
              h="100%"
              bg="white"
              borderRadius="2xl"
              borderColor="grey.200"
              borderWidth={1}
              mx="5">
              <SearchComponent
                searchValue={searchValue}
                handleSubmit={handleSubmit}
                setSearchStatus={setSearchStatus}
                searchStatus={searchStatus}
                timeFilters={timeFilters}
                categoryFilters={categoryFilters}
                moderatorsFilters={moderatorFilters}
              />
            </Flex>
          </Flex>
        </VStack>
      )}
    </>
  )
}

export default secureScreen(SearchScreen)
