import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { capitalCase } from 'capital-case'
import { useState } from 'react'
import { Platform } from 'react-native'
import Configuration from '../../../domain/services/ConfigurationService'

import IntercomWrapper from '../components/IntercomWrapper'
import CreatingOasisScreen from '../components/onboarding/CreatingOasisScreen'
import MyGroups from '../components/profile/MyGroups'
import SuccessModalWrapper from '../components/SuccessModalWrapper'
import useAuthentication from '../hooks/authentication/useAuthentication'
import usePageTracking from '../hooks/customerio/usePageTracking'
import { useFeatures } from '../providers/FeatureProvider'
import { UserProfileProvider } from '../providers/UserProfileProvider'
import ActivityScreen from './ActivityScreen'
import AdminScreen from './AdminScreen'
import AudioPlayerScreen from './AudioPlayerScreen'
import BookmarksScreen from './BookmarksScreen'
import CollectionScreen from './CollectionScreen'
import CollectionsScreen from './CollectionsScreen'
import ExploreScreen from './explore/ExploreScreen'
import FullPDFScreen from './explore/FullPDFScreen'
import GroupCalendarScreen from './explore/GroupCalendarScreen'
import SearchScreen from './explore/SearchScreen'
import HomeScreen from './HomeScreen'
import LoginCallbackScreen from './login/LoginCallbackScreen'
import OchsnerScreen from './login/OchsnerScreen'
import OchsnerSignupScreen from './login/OchsnerSignupScreen'
import LoginWithPasswordScreen from './LoginWithPasswordScreen'
import ModeratorDetailScreen from './moderators/ModeratorDetailScreen'
import ModeratorsScreen from './moderators/ModeratorsScreen'
import NotFoundScreen from './NotFoundScreen'
import LandingScreen from './onboarding/LandingScreen'
import PersonalizationScreen from './onboarding/PersonalizationScreen'
import VerifyAccessCodeScreen from './onboarding/VerifyAccessCodeScreen'
import VerifyEligibilityScreen from './onboarding/VerifyEligibilityScreen'
import AboutScreen from './profile/AboutScreen'
import AccountScreen from './profile/AccountScreen'
import EAPScreen from './profile/EAPScreen'
import HealthMinutesScreen from './profile/HealthMinutesScreen'
import InsightsScreen from './profile/InsightsScreen'
import LeaderboardScreen from './profile/LeaderboardScreen'
import SelectRoleScreen from './profile/SelectRoleScreen'
import VibeCheckHistoryScreen from './profile/VibeCheckHistoryScreen'
import VibeCheckReviewScreen from './profile/VibeCheckReviewScreen'
import ProfileScreen from './ProfileScreen'
import ReferralScreen from './ReferralScreen'
import ReferralSignUpScreen from './ReferralSignupScreen'
import RoomScreen from './RoomScreen'
import RoomTestScreen from './RoomTestScreen'
import RoomTestScreenWithUI from './RoomTestScreenWithUI'
import VibeCheckScreen from './VibeCheckScreen'
import CompassFormScreen from './voyages/CompassFormScreen'
import CompassResultsScreen from './voyages/CompassResultsScreen'
import CompassScreen from './voyages/CompassScreen'
import CompletedScreen from './voyages/CompletedScreen'
import FocusAreaScreen from './voyages/FocusAreaScreen'
import LandingVoyageScreen from './voyages/LandingVoyageScreen'
import PathScreen from './voyages/PathScreen'
import VoyagesIntroScreen from './voyages/VoyagesIntroScreen'
import VoyagesScreen from './voyages/VoyagesScreen'
import VoyagesScreenResultsScreen from './voyages/VoyagesScreenerResultsScreen'
import VoyagesScreenerScreen from './voyages/VoyagesScreenerScreen'

export type NavigationParams = {
  Home: undefined
  Activity: { activityId?: string; stepId?: string }
  Admin: undefined
  Explore: undefined
  Profile: { subscreen?: string }
  Personalization: undefined
  Room: {
    roomId: string
    activityId?: string
  }
  Search: {
    categoryFilters?: number
    moderatorsFilters?: number
    timeFilters?: number
    searchValue?: string
  }
  VibeCheck: undefined
  VibeReview: {
    picked: string
    comment: string
    date: string
  }
  VibeCheckHistory: undefined
  MyGroups: undefined
  HealthMinutes: undefined
  EAP: undefined
  About: undefined
  Account: undefined
  AudioPlayer: undefined
  FullPDF: undefined
  NotFound: undefined
  LiveGroups: undefined
  RoleReview: undefined
  MentalHealth: undefined
  CreatingOasis: undefined
  Insights: undefined
  '404': undefined
  Referral: undefined
  Bookmarks: undefined
  Moderators: undefined
  ModeratorDetail: {
    moderatorId: string
  }
  Voyage: undefined
  VoyageScreener: undefined
  VoyageScreenerResults: undefined
  VoyageIntro: undefined
  LandingVoyage: undefined
  FocusArea: {
    focusArea: string
  }
  Path: {
    focusArea: string
    id: string
  }
  Completed: undefined
  Compass: undefined
  CompassForm: undefined
  CompassResults: undefined
  ChangePassword: undefined
  Collection: {
    slug: string
  }
  Collections: undefined
  Leaderboard: undefined

  Landing: undefined
  Login: { email?: string }
  LoginCallback: undefined
  Ochsner: undefined
  Signup: { email: string; referralCode?: string }
  VerifyEligibility: { email: string }
  VerifyAccessCode: undefined
  OchsnerSignup: undefined
  AccountCreated: { userEmail: string }
  CreateAccount: { email: string; referralCode?: string }
  LoginWithPassword: undefined
  ReferralSignUp: undefined
  RoomTestScreen: {
    roomId: string
  }
}

const NavigationScreen = () => {
  const { isLoading } = useAuthentication()
  const [routeName, setRouteName] = useState(undefined)
  const [routeParams, setRouteParams] = useState(undefined)
  const { features } = useFeatures()
  const navigationRef = usePageTracking()

  const linking = {
    prefixes: [`${Configuration.SCHEME}://`],
    config: {
      screens: {
        ...navigationLinks,
      },
    },
  }

  if (isLoading) return

  const Stack = createNativeStackNavigator<NavigationParams>()

  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) => {
          return `Cabana - ${capitalCase(options?.title || route?.name || '')}`
        },
      }}
      linking={linking}
      onReady={() => {
        setRouteName(navigationRef.getCurrentRoute()?.name)
      }}
      onStateChange={() => {
        const { name: currentRouteName, params: currentRouteParams } =
          navigationRef.getCurrentRoute()
        setRouteName(currentRouteName)
        setRouteParams(currentRouteParams)
      }}
      ref={navigationRef}>
      <IntercomWrapper routeName={routeName}>
        <SuccessModalWrapper routeParams={routeParams}>
          <UserProfileProvider>
            <Stack.Navigator
              initialRouteName="Landing"
              screenOptions={{ headerShown: false }}>
              <Stack.Screen
                name="Activity"
                component={ActivityScreen}
                initialParams={{ activityId: undefined }}
              />
              <Stack.Screen
                name="Admin"
                component={AdminScreen}
                options={{ animation: 'none' }}
              />
              <Stack.Screen
                name="Explore"
                component={ExploreScreen}
                options={{ animation: 'none' }}
              />
              <Stack.Screen
                name="Home"
                component={HomeScreen}
                options={{ animation: 'fade' }}
              />
              <Stack.Screen name="LiveGroups" component={GroupCalendarScreen} />
              <Stack.Screen
                name="Personalization"
                component={PersonalizationScreen}
                options={{ animation: 'none' }}
              />
              <Stack.Screen
                name="Profile"
                component={ProfileScreen}
                options={{ animation: 'none' }}
              />
              <Stack.Screen name="RoleReview" component={SelectRoleScreen} />
              <Stack.Screen
                name="Room"
                component={
                  features.LIVE_GROUP_ROOM_3_0
                    ? RoomTestScreenWithUI
                    : RoomScreen
                }
              />
              <Stack.Screen
                name="Search"
                component={SearchScreen}
                options={{ animation: 'none' }}
              />
              <Stack.Screen name="VibeCheck" component={VibeCheckScreen} />
              <Stack.Screen
                name="VibeReview"
                component={VibeCheckReviewScreen}
              />

              <Stack.Screen name="404" component={NotFoundScreen} />
              <Stack.Screen name="About" component={AboutScreen} />
              <Stack.Screen name="Account" component={AccountScreen} />
              <Stack.Screen name="AudioPlayer" component={AudioPlayerScreen} />
              <Stack.Screen
                name="CreatingOasis"
                component={CreatingOasisScreen}
                options={{ animation: 'fade' }}
              />
              <Stack.Screen name="EAP" component={EAPScreen} />
              <Stack.Screen name="FullPDF" component={FullPDFScreen} />
              <Stack.Screen
                name="MentalHealth"
                component={HealthMinutesScreen}
              />
              <Stack.Screen name="MyGroups" component={MyGroups} />
              <Stack.Screen
                name="VibeCheckHistory"
                component={VibeCheckHistoryScreen}
              />
              <Stack.Screen name="Insights" component={InsightsScreen} />
              <Stack.Screen name="Referral" component={ReferralScreen} />
              <Stack.Screen name="Bookmarks" component={BookmarksScreen} />
              <Stack.Screen name="Moderators" component={ModeratorsScreen} />
              <Stack.Screen
                name="ModeratorDetail"
                component={ModeratorDetailScreen}
              />
              <Stack.Screen name="Voyage" component={VoyagesScreen} />
              <Stack.Screen
                name="VoyageScreener"
                component={VoyagesScreenerScreen}
              />
              <Stack.Screen
                name="VoyageScreenerResults"
                component={VoyagesScreenResultsScreen}
              />
              <Stack.Screen name="VoyageIntro" component={VoyagesIntroScreen} />
              <Stack.Screen
                name="LandingVoyage"
                component={LandingVoyageScreen}
              />
              <Stack.Screen name="FocusArea" component={FocusAreaScreen} />
              <Stack.Screen name="Path" component={PathScreen} />
              <Stack.Screen name="Completed" component={CompletedScreen} />
              <Stack.Screen name="Compass" component={CompassScreen} />
              <Stack.Screen name="CompassForm" component={CompassFormScreen} />
              <Stack.Screen
                name="CompassResults"
                component={CompassResultsScreen}
              />
              <Stack.Screen name="Collection" component={CollectionScreen} />
              <Stack.Screen name="Collections" component={CollectionsScreen} />
              <Stack.Screen name="Leaderboard" component={LeaderboardScreen} />

              <Stack.Screen name="Landing" component={LandingScreen} />
              <Stack.Screen name="Ochsner" component={OchsnerScreen} />

              <Stack.Screen
                name="VerifyEligibility"
                component={VerifyEligibilityScreen}
              />
              <Stack.Screen
                name="VerifyAccessCode"
                component={VerifyAccessCodeScreen}
              />
              <Stack.Screen
                name="LoginCallback"
                component={LoginCallbackScreen}
              />
              <Stack.Screen
                name="OchsnerSignup"
                component={OchsnerSignupScreen}
              />
              <Stack.Screen
                name="LoginWithPassword"
                component={LoginWithPasswordScreen}
              />
              <Stack.Screen
                name="ReferralSignUp"
                component={ReferralSignUpScreen}
              />
              <Stack.Screen name="RoomTestScreen" component={RoomTestScreen} />
            </Stack.Navigator>
          </UserProfileProvider>
        </SuccessModalWrapper>
      </IntercomWrapper>
    </NavigationContainer>
  )
}

const navigationLinks = {
  Activity: '/activity',
  Admin: '/admin',
  Explore: '/explore',
  Home: '/home',
  LiveGroups: '/live-groups',
  Personalization: '/personalization',
  Profile: '/profile/:subscreen',
  RoleReview: '/role-review',
  Room: '/room/:roomId',
  Search: '/search',
  VibeCheck: '/vibe-check',
  VibeReview: '/vibe-review',
  Moderators: '/moderators',
  ModeratorDetail: '/moderators/:moderatorId',
  Voyage: '/voyage',
  VoyageScreener: '/voyage/screener',
  VoyageScreenerResults: '/voyage/screener/results',
  VoyageIntro: '/voyage/video-intro',
  LandingVoyage: '/voyage/landing',
  FocusArea: '/voyage/focus-area/:focusArea',
  Path: '/voyage/path/:id',
  Completed: '/voyage/completed',
  Compass: '/voyage/compass',
  CompassForm: '/voyage/compass/values',
  CompassResults: '/voyage/compass/results',
  ChangePassword: '/profile/change-password',
  VerifyEligibility: '/verify-eligibility',
  VerifyAccessCode: '/verify-account',
  Collection: '/collection/:slug',
  Collections: '/collections',
  404: '*',
  Referral: `${Platform.OS === 'ios' && '/profile/referrals'}`,
  Landing: '/landing',
  Login: '/login',
  LoginCallback: '/login-callback',
  Ochsner: '/ochsner',
  Signup: '/sign-up',
  CreateAccount: '/create-account',
  OchsnerSignup: '/create-organization-account',
  LoginWithPassword: '/login-with-password',
  ReferralSignUp: '/referral',
  RoomTestScreen: '/room-test',
}

export default NavigationScreen
