import { Divider, HStack, Text } from 'native-base'
import { useEffect, useState } from 'react'
import Markdown from 'react-native-marked'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import mdStylesDesktop from '../../constants/mdStylesDesktop'
import mdStylesMobile from '../../constants/mdStylesMobile'
import { useCabanaApi } from '../../providers/CabanaApiProvider'
import useCustomerIo from '../../hooks/customerio/useCustomerIo'

const EmployersResources = () => {
  const { EapApi } = useCabanaApi()
  const isDesktop = useDesktopBreakpoint()
  const customerIo = useCustomerIo()
  const [eapPage, setEapPage] = useState('')

  useEffect(() => {
    ;(async () => {
      const eapPage = await EapApi.getEapPage()
      setEapPage(eapPage.data?.eapBodyText)
      if (isDesktop) {
        customerIo.track('EAP Reached')
      }
    })()
  }, [])

  return (
    <HStack
      borderRadius="2xl"
      p={4}
      mx={isDesktop ? 0 : 5}
      mt={isDesktop ? 0 : 5}
      mb={isDesktop ? 0 : -5}
      bg="white"
      flexDirection="column"
      borderColor="grey.200"
      borderWidth={1}>
      <Text fontSize="16px" color="primary.900" fontWeight={700}>
        My resources
      </Text>
      <Divider my={4} color="muted.300" />
      <Markdown
        value={eapPage}
        flatListProps={{
          initialNumToRender: 8,
          scrollEnabled: false,
          contentContainerStyle: { backgroundColor: 'white' },
        }}
        styles={!isDesktop ? mdStylesMobile : mdStylesDesktop}
      />
    </HStack>
  )
}

export default EmployersResources
