import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useEffect, useMemo, useState } from 'react'
import { Platform } from 'react-native'
import { useDesktopBreakpoint } from '../../../domain/services/useBreakpoint'
import { useCabanaApi } from '../providers/CabanaApiProvider'
import { NavigationParams } from '../screens/NavigationScreen'
import useAuthentication from './authentication/useAuthentication'
import useIntercom from './useIntercom'
import useLinkHandler from './useLinkHandler'
import useCustomerIo from './customerio/useCustomerIo'

export default () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const { logout } = useAuthentication()
  const isDesktop = useDesktopBreakpoint()
  const { EapApi } = useCabanaApi()
  const [hasEap, setHasEap] = useState(false)
  const { openChat } = useIntercom()
  const customerIo = useCustomerIo()

  useEffect(() => {
    ;(async () => {
      try {
        const eapPage = await EapApi.getEapPage()
        setHasEap(eapPage.data)
      } catch (e) {
        setHasEap(false)
      }
    })()
  }, [])

  const VibeCheckSection = [
    {
      name: 'Mental health minutes',
      id: 'health-minutes',
      icon: 'star',
      hasRightIcon: true,
      action: () => {},
    },
    {
      name: 'Leaderboard',
      id: 'leaderboard',
      icon: 'medal',
      hasRightIcon: true,
      action: () => !isDesktop && navigation.navigate('Leaderboard'),
    },
    {
      id: 'vibe-check-history',
      name: 'Vibe check',
      icon: 'wave-pulse',
      hasRightIcon: true,
      action: () => !isDesktop && navigation.navigate('VibeCheckHistory'),
    },
    {
      id: 'referrals',
      name: 'Referrals',
      icon: 'star',
      hasRightIcon: true,
      action: () => !isDesktop && navigation.navigate('Referral'),
    },
  ]

  const MyContentSection = [
    {
      id: 'bookmarks',
      name: 'Bookmarks',
      icon: 'bookmark solid',
      hasRightIcon: true,
      action: () => !isDesktop && navigation.navigate('Bookmarks'),
    },
    {
      id: 'my-groups',
      name: 'My groups',
      icon: 'user-group',
      hasRightIcon: true,
      action: () => !isDesktop && navigation.navigate('MyGroups'),
    },
  ]

  const AccountSection = [
    {
      id: 'account',
      name: 'Account',
      icon: 'gear',
      hasRightIcon: true,
      action: () => !isDesktop && navigation.navigate('Account'),
    },
  ]

  const HelpSection = [
    ...(hasEap && isDesktop
      ? [
          {
            id: 'eap',
            name: 'My resources',
            icon: 'id-badge',
            hasRightIcon: true,
            action: () => !isDesktop && navigation.navigate('EAP'),
          },
        ]
      : []),
    {
      id: 'feature-request',
      name: 'Feature request and feedback',
      icon: 'comment',
      hasRightIcon: true,
      rightIcon: 'arrow-up-right-from-square',
      action: () => useLinkHandler('https://cabana.upvoty.com/b/feedback/'),
    },
    {
      id: 'faq',
      name: 'Frequently asked questions',
      icon: 'question-circle',
      hasRightIcon: true,
      rightIcon: 'arrow-up-right-from-square',
      action: () => useLinkHandler('https://help.yourcabana.com/en/'),
    },
    {
      id: 'crisis-support',
      name: 'Crisis support',
      icon: 'life-ring',
      hasRightIcon: true,
      rightIcon: 'arrow-up-right-from-square',
      action: () => {
        customerIo.track('Crisis Support Clicked')
        useLinkHandler('https://www.mycabana.health/resources')
      },
    },
    {
      id: ' contact-tech-support',
      name: 'Contact tech support',
      icon: 'screwdriver-wrench',
      hasRightIcon: true,
      rightIcon: 'arrow-up-right-from-square',
      action: openChat,
    },
  ]

  const SystemSection = [
    {
      id: 'about',
      name: 'About',
      icon: 'circle-info',
      hasRightIcon: true,
      action: () => !isDesktop && navigation.navigate('About'),
    },
    {
      id: 'account',
      name: 'Account',
      icon: 'gear',
      hasRightIcon: true,
      action: () => !isDesktop && navigation.navigate('Account'),
    },
    {
      id: 'logout',
      name: 'Logout',
      icon: 'arrow-right-from-bracket',
      hasRightIcon: false,
      action: () =>
        logout().then(() => {
          if (Platform.OS !== 'web') navigation.navigate('Landing')
        }),
    },
  ]

  const ProfileActions = useMemo(
    () => [
      {
        title: 'Vibe check',
        data: [...(isDesktop ? VibeCheckSection : [])],
      },
      {
        title: 'Content',
        data: [...(isDesktop ? MyContentSection : [])],
      },
      {
        title: 'Account',
        data: [...(isDesktop ? AccountSection : [])],
      },
      {
        title: 'Help',
        data: HelpSection,
      },
      {
        title: 'System',
        data: SystemSection,
      },
    ],
    [hasEap, openChat]
  )

  return {
    ProfileActions,
    VibeCheckSection,
    AccountSection,
    HelpSection,
    SystemSection,
    MyContentSection,
  }
}
