import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  IconButton,
  Input,
  KeyboardAvoidingView,
  ScrollView,
  Text,
  Tooltip,
  VStack,
} from 'native-base'
import { useEffect, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
} from '../../../../../domain/services/useBreakpoint'
import useGetShadowProps from '../../../hooks/useGetShadowProps'
import useInvalidateQueries from '../../../hooks/useInvalidateQueries'
import useMobileKeyboardListeners from '../../../hooks/useMobileKeyboardListeners'
import BaseLayout from '../../../layouts/BaseLayout'
import { useRoomContext } from '../../../providers/Room30Provider'
import { NavigationParams } from '../../../screens/NavigationScreen'
import FeedbackSubmitted from '../../room/feedback/FeedbackSubmitted'
import Card from '../../_shared/card/Card'
import FaIcon from '../../_shared/FaIcon'
import StarRating from '../../_shared/form/StarRating'
import ProgressModal from '../../_shared/ProgressModal'
import useCustomerIo from '../../../hooks/customerio/useCustomerIo'

const RoomFeedback = () => {
  if (Platform.OS === 'web') {
    return (
      <BaseLayout>
        <RoomFeedbackView />
      </BaseLayout>
    )
  }
  return <RoomFeedbackView />
}

const RoomFeedbackView = () => {
  const { join, me, info, submitFeedback } = useRoomContext()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
    const customerIo = useCustomerIo()

  const [thumbsUp, setThumbsUp] = useState(null)
  const [overallRating, setOverallRating] = useState(null)
  const [technologyRating, setTechnologyRating] = useState(null)
  const [groupCohesionRating, setGroupCohesionRating] = useState(null)
  const [moderatorRating, setModeratorRating] = useState(null)
  const [comments, setComments] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const shadowProps = useGetShadowProps()
  const isMobile = useMobileBreakpoint()
  const { height } = useWindowDimensions()
  const { keyboardOpen, closeKeyboard } = useMobileKeyboardListeners()
  const { invalidateQueries } = useInvalidateQueries()
  const desktop = useDesktopBreakpoint()

  const rejoin = async () => {
    join()
  }

  const goHome = () => {
    navigation.replace('Home')
  }

  const minBottomPadding = 75
  const mobilePadding = isMobile ? 90 : 0
  const minDeviceHeight = 640 + mobilePadding
  const heightDifference = height - minDeviceHeight
  const bottomPadding =
    heightDifference < minBottomPadding ? minBottomPadding : heightDifference
  const keyboardPaddingTop = keyboardOpen ? undefined : '24px'
  const isModerator = me.role === AttendeeRole.Moderator

  const submitingFeedback = () => {
    if (me.role !== AttendeeRole.Moderator) {
      customerIo.track('Post Live Group Survey Submitted', {
        Title: info.title,
        'Overall Rating': overallRating,
        'Technology Rating': technologyRating,
        'Moderator Rating': moderatorRating,
        Comments: comments,
      })
    }
    setIsSubmitting(true)
  }

  const onKeyPress = (event) => {
    if (event.nativeEvent.key === 'Enter') {
      event.preventDefault()
      if (isMobile) closeKeyboard()
    }
  }

  useEffect(() => {
    invalidateQueries('Activity')
  }, [])

  if (me.feedbackSubmitted) return <FeedbackSubmitted />

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      style={{ flex: 1 }}
      keyboardVerticalOffset={bottomPadding / 2 < 50 ? 0 : -bottomPadding / 2}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'flex-start',
        }}
        scrollEnabled={true}
        bounces={false}>
        <Center
          safeAreaTop
          pt={isMobile ? keyboardPaddingTop : '100px'}
          pb={bottomPadding + 'px'}>
          <Heading
            textAlign="center"
            pb={me.role == AttendeeRole.Moderator ? '32px' : undefined}>
            {me.role === AttendeeRole.Moderator
              ? 'Rate today’s group'
              : `Thank you${isMobile ? '\n' : ' '}for attending!`}
          </Heading>
          {!isModerator && (
            <HStack space="3" mt="5" mb="8">
              {info.canRejoin ? (
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onPress={rejoin}>
                  Rejoin
                </Button>
              ) : (
                <Tooltip label="Oops! The group has ended" placement="top">
                  <Box>
                    <Button isDisabled colorScheme="primary" variant="outline">
                      Rejoin
                    </Button>
                  </Box>
                </Tooltip>
              )}
              <Button colorScheme="primary" onPress={goHome}>
                Return to home
              </Button>
            </HStack>
          )}
          <Card
            boxProps={{
              ...shadowProps,
              h: Platform.OS === 'web' ? undefined : 523,
              p: '16px',
            }}
            body={
              <VStack flex={1}>
                <Center>
                  {!isModerator && (
                    <Heading size="md">
                      Do you feel better
                      {'\n'}
                      after this group?
                    </Heading>
                  )}
                  <HStack>
                    <IconButton
                      icon={
                        <FaIcon
                          name="thumbs-up"
                          size={24}
                          color="success.500"
                        />
                      }
                      isHovered={thumbsUp}
                      colorScheme="success"
                      borderRadius="full"
                      onPress={() => setThumbsUp(true)}
                    />
                    <IconButton
                      icon={
                        <FaIcon
                          name="thumbs-down"
                          size={24}
                          color="error.500"
                        />
                      }
                      isHovered={thumbsUp === false}
                      colorScheme="error"
                      borderRadius="full"
                      onPress={() => setThumbsUp(false)}
                    />
                  </HStack>
                </Center>
                <VStack mt="24px">
                  <StarRating
                    label="Overall"
                    rating={overallRating}
                    setRating={setOverallRating}
                  />
                  <StarRating
                    label="Technology"
                    rating={technologyRating}
                    setRating={setTechnologyRating}
                  />
                  {isModerator && (
                    <StarRating
                      label="Group cohesion"
                      rating={groupCohesionRating}
                      setRating={setGroupCohesionRating}
                    />
                  )}
                  {!isModerator && (
                    <StarRating
                      label="Moderator"
                      rating={moderatorRating}
                      setRating={setModeratorRating}
                    />
                  )}
                </VStack>

                <Input
                  flex={1}
                  size="lg"
                  maxHeight={96}
                  scrollEnabled
                  placeholder="Comments"
                  mt="70px"
                  value={comments}
                  onChangeText={setComments}
                  returnKeyType="done"
                  pb="55px"
                  multiline={true}
                  onKeyPress={isMobile && onKeyPress}
                />
                <Button
                  colorScheme="primary"
                  w="100%"
                  mt="32px"
                  onPress={submitingFeedback}>
                  Submit
                </Button>
              </VStack>
            }
          />
        </Center>
      </ScrollView>
      <ProgressModal
        downloadProgress={isSubmitting ? 100 : 0}
        isVisible={isSubmitting}
        progressMessage=""
        successMessage={
          <>
            <Text
              fontWeight="700"
              color="text.900">{`Feedback submitted!`}</Text>
            <Text>
              {`\nYou’re being redirected to the live group `}
              <Text underline>{`details`}</Text>
              {` page.`}
            </Text>
          </>
        }
        animationDuration={2000}
        position={
          isMobile
            ? {
                top: 520,
              }
            : { top: 0, right: 0 }
        }
        callback={() => {
          setIsSubmitting(false)
          if (desktop) {
            navigation.navigate('Activity', { activityId: info.id })
          } else {
            navigation.navigate('Home')
          }
          if (isModerator) {
            const feedback = {
              thumbsUp,
              overallRating,
              technologyRating,
              groupCohesionRating,
              comments,
            }
            submitFeedback(feedback)
          } else {
            const feedback = {
              thumbsUp,
              overallRating,
              technologyRating,
              moderatorRating,
              comments,
            }
            submitFeedback(feedback)
          }
        }}
      />
    </KeyboardAvoidingView>
  )
}

export default RoomFeedback
